import { Link } from "gatsby";
import { URLS } from '../constants/common';
import React from "react";
import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";

const PrivacyPolicy = (props) => {
  return (
    <Layout>
      <SEO title="Cookie Policy" description="Cookie Policy for Hacking the Grow website" />
      <div className="mt-24 mb-16">
        <h1 className="text-3xl font-bold mt-10 mb-4">
          Cookie Policy for Hacking the Grow
        </h1>

        <p className="mt-0 inline leading-normal">
          This is the Cookie Policy for Hacking the Grow, accessible from
          https://hackingthegrow.com
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          <strong>What Are Cookies</strong>
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          <strong>How We Use Cookies</strong>
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          <strong>Disabling Cookies</strong>
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies. This
          Cookies Policy was created with the help of the{" "}
          <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">
            Cookies Policy Generator from CookiePolicyGenerator.com
          </a>
          .
        </p>
        <br />
        <p className="mt-0 inline leading-normal">
          <strong>The Cookies We Set</strong>
        </p>
        <br />

        <ul>
          <li>
            <p className="mt-0 inline leading-normal">
              Site preferences cookies
            </p>
            <br />
            <p className="mt-0 inline leading-normal">
              In order to provide you with a great experience on this site we
              provide the functionality to set your preferences for how this
              site runs when you use it. In order to remember your preferences
              we need to set cookies so that this information can be called
              whenever you interact with a page is affected by your preferences.
            </p>
            <br />
          </li>
        </ul>

        <p className="mt-0 inline leading-normal">
          <strong>Third Party Cookies</strong>
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </p>
        <br />

        <ul>
          <li>
            <p className="mt-0 inline leading-normal">
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content.
            </p>
            <br />
            <p className="mt-0 inline leading-normal">
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </p>
            <br />
          </li>

          <li>
            <p className="mt-0 inline leading-normal">
              Several partners advertise on our behalf and affiliate tracking
              cookies simply allow us to see if our customers have come to the
              site through one of our partner sites so that we can credit them
              appropriately and where applicable allow our affiliate partners to
              provide any bonus that they may provide you for making a purchase.
            </p>
            <br />
          </li>
        </ul>

        <p className="mt-0 inline leading-normal">
          <strong>More Information</strong>
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          For more general information on cookies, please read{" "}
          <a href="https://www.privacypolicyonline.com/what-are-cookies/">
            "What Are Cookies"
          </a>
          .
        </p>
        <br />

        <p className="mt-0 inline leading-normal">
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
        </p>
        <br />

        <ul>
          <li>Email: hackingthegrow@gmail.com</li>
          <li>
            By visiting this link: <Link to={URLS['contact']}>here</Link>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
